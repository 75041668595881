import { configType } from './config'

const producerkitsProduction: configType = {
  firebaseConfig: {
    apiKey: 'AIzaSyAS-uEo0my-Z8cn3jGaYxxhEuPv-y960f0',
    authDomain: 'producerkits-production.firebaseapp.com',
    projectId: 'producerkits-production',
    storageBucket: 'producerkits-production.appspot.com',
    messagingSenderId: '289824181931',
    appId: '1:289824181931:web:1f0784e1bd9574b29471ee',
    measurementId: 'G-PJ02LZ9401',
  },
  firebaseEmulator: false,
  environment: 'production',
  googleTagManagerID: 'GTM-NFSFS5M',
  paypalClientID:
    'AXT3R-sRnMLr2O_HvjSCXAsmO_a2Q9j1PuQCYjMUM7hdrI74TFkX6_hmKVj41sWtzDVHwrtl9DIl0gnj',
  // backendURL needs a / at the end
  backendURL:
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://producerkits-production.uc.r.appspot.com/',
  stripePublicKey:
    'pk_live_51MK5HsKBq6N8CQ53FzZqbJLvHjKqMvhdPlfpo6Vbac8paOE8yYNf9hOdfuCL0Yb70y6g5FsFgXugwZLU847g5Zus00usPHGZTG',
}

export default producerkitsProduction
