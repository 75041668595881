import { configType } from './config'

const producerkitsStaging: configType = {
  firebaseConfig: {
    apiKey: 'AIzaSyDihTQ-XhqhUgGxrzI9Y6KEmIox_kpKbZI',
    authDomain: 'producerkits-staging.firebaseapp.com',
    projectId: 'producerkits-staging',
    storageBucket: 'producerkits-staging.appspot.com',
    messagingSenderId: '50977950175',
    appId: '1:50977950175:web:316f8d8471704f2e141c6a',
    measurementId: 'G-NFWV2RCN7M',
  },
  firebaseEmulator: false,
  environment: 'staging',
  googleTagManagerID: 'GTM-NFSFS5M',
  paypalClientID:
    'ASXAWWLecZTFmTvpw3SgJykOEx_i39lFLG9oB8nsNZtFYJXajRqHOLFqgOzC7e9apPmCJQou-3gcav9H',
  // backendURL needs a / at the end
  backendURL:
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://producerkits-staging.uc.r.appspot.com/',
  stripePublicKey:
    'pk_test_51Lcp51L5Q0B9DVXlmKnWwMkoUKuB4adJhRHDwezPACFHuyn4B28xXAPyrg1CgQwD5PuNWlyBzdm1ILo0G4kdIWE100mtj1S9YS',
}

export default producerkitsStaging
