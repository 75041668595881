import React, { useEffect } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider } from 'styles/ThemeProvider'
import { StoreProvider } from 'hooks/useStore'
import { CacheProvider } from '@emotion/react'
import { googleTagManagerPageview } from 'containers/GoogleTracking/utils'
import createCache from '@emotion/cache'
import { config } from 'configs/config'
interface AppRootProps extends AppProps {
  emotionCache: any
}
export function createEmotionCache() {
  return createCache({ key: 'css' })
}
const clientSideEmotionCache = createEmotionCache()

function Application({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppRootProps) {
  const router = useRouter()

  /** Google Tracking helpers, when route changes pageview helpers are triggered. */
  useEffect(() => {
    const handleRouteChange = url => {
      googleTagManagerPageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <CacheProvider value={emotionCache}>
      <Script id='gtm-script' strategy='afterInteractive'>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${config.googleTagManagerID}');`}
      </Script>
      <Head>
        <title>ProducerKits</title>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      </Head>
      <StoreProvider>
        <ThemeProvider>
          <Component {...pageProps} />
        </ThemeProvider>
      </StoreProvider>
      <Script type='text/javascript'>
        {`(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', '1002314098');
    vgo('setTrackByDefault', true);

    vgo('process');`}
      </Script>
    </CacheProvider>
  )
}
// @ts-ignore
const TranslateApplication = appWithTranslation(Application)

export default TranslateApplication
