import producerkitsProduction from './config-production'
import producerkitsStaging from './config-staging'
import producerkitsDev from './config-development'

export type configType = {
  firebaseConfig: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    measurementId: string
    appId: string
  }
  firebaseEmulator?: boolean
  environment: 'development' | 'production' | 'staging'
  paypalClientID: string
  googleTagManagerID: string
  backendURL: string
  stripePublicKey: string
}

export const config =
  process.env.NEXT_PUBLIC_PROJECT === 'producerkits-production'
    ? producerkitsProduction
    : process.env.NEXT_PUBLIC_PROJECT === 'producerkits-staging'
    ? producerkitsStaging
    : producerkitsDev
