import React from 'react'
import MaterialThemeProvider from '@mui/material/styles/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { useStore } from 'hooks/useStore'
import { theme } from './theme'

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  const [{ themeMode }] = useStore()
  const isDark = themeMode === 'dark'
  return (
    <MaterialThemeProvider theme={theme(isDark)}>
      <CssBaseline />
      {React.Children.only(props.children)}
    </MaterialThemeProvider>
  )
}
