import createTheme from '@mui/material/styles/createTheme'
import { sharedTheme } from 'data/sharedTheme'

export const theme = (isDark: boolean) => {
  const materialTheme = createTheme()
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
    palette: {
      mode: isDark ? 'dark' : 'light',
      primary: {
        main: sharedTheme.primary,
        dark: '#c34400',
        light: '#ffa548',
      },
      secondary: {
        main: '#212121',
        dark: '#000000',
        light: '#484848',
      },
      text: {
        secondary: 'rgba(153, 153, 159, 1)',
      },
    },
    typography: {
      fontFamily: ['Biennale', 'sans-serif'].join(','),
      h1: {
        fontWeight: 400,
        fontSize: 56,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 48,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 42,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 36,
        },
      },
      h2: {
        fontWeight: 700,
        fontSize: 40,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 36,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 30,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 28,
        },
      },
      h3: {
        fontWeight: 400,
        fontSize: 36,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 32,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 28,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 24,
        },
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 26,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 24,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      },
      h5: {
        fontWeight: 700,
        fontSize: 26,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 24,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 22,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },
      h6: {
        fontWeight: 700,
        fontSize: 22,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 19,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 17,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: 24,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 22,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 20,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: 20,
        color: '#fff',
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 18,
        },
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        color: '#fff',
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 15,
        },
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        color: '#fff',
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 13,
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
          },
          '& #__next': {
            minHeight: '100vh',
            background: 'rgb(0,0,0,0)',
            backgroundImage:
              'linear-gradient(180deg, rgb(44, 32, 30, 1) 0px, rgba(22, 26, 26, 0)  400px, rgba(115, 41, 21, 0)  450px, rgba(115, 41, 21, 0)  100%)',
          },
          body: {
            backgroundColor: 'rgb(26,26,26)',
          },
          a: {
            textDecoration: 'none',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiAutocomplete-inputRoot': {
              '& .MuiAutocomplete-input': {
                padding: '16.5px 14px',
                paddingRight: '0px',
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fieldset: {
              border: '2px solid white',
            },
          },
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #666666 inset',
            },
          },
        },
      },
    },
  })
}
